<template>
  <div ref="swiperEl" class="swiper-container">
    <div class="swiper-wrapper">
      <slot name="default"></slot>
    </div>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, watch } from '@vue/composition-api'

/*! purgecss ignore */
import 'swiper/swiper-bundle.css'
import Swiper from 'swiper/bundle'

export default {
  name: 'Swiper',
  components: {},
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  setup(props, context) {
    const swiperEl = ref(null)

    let instance
    onMounted(() => {
      instance = new Swiper(swiperEl.value, {
        // cssMode: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        slidesPerGroup: 2,
        freeMode: true,
        freeModeSticky: true,
        slidesPerView: 'auto',
        mousewheel: true,
        keyboard: true
      })
    })
    onUnmounted(() => {
      instance.destroy()
    })

    watch(
      () => props.items.length,
      () => {
        if (instance) {
          instance.update()
        }
      }
    )

    return {
      swiperEl
    }
  }
}
</script>

<style lang="postcss" scoped></style>
