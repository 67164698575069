<template>
  <Dock :style="dockStyle">
    <div ref="dockRef" class="pb-2 mt-2 text-left" style="min-height: 200px">
      <DiscoverCategories
        :primary-category="primaryCategory"
        :category-id-stack.sync="categoryIdStack"
        :categories="categories"
        :env="env"
        class="max-w-full mb-1 overflow-x-auto overflow-y-hidden"
      />
      <!-- Infoboxes -->
      <div class="px-2">
        <Slider v-if="categoryIdStack.length">
          <div class="flex">
            <DiscoverInfobox
              v-for="scene in categoryItems"
              :key="scene._id"
              class="mr-1.5 swiper-slide"
              :scene="scene"
              :env="env"
              :current-category="currentCategory"
              :link="makeLink({ scene, service: scene.service, env })"
              @add-to-planner="() => handleAddToPlanner({ scene })"
              @mouseover="() => handleItemHover({ item: scene })"
              @open-link="({ link }) => handleOpenLink({ link, item: scene })"
            />
          </div>
        </Slider>

        <Slider v-else>
          <!-- Front Page Items -->
          <div class="flex">
            <DiscoverInfobox
              v-for="frontPageItem in frontPageItems"
              :key="frontPageItem._id"
              class="mr-1.5 swiper-slide"
              :service="frontPageItem.service"
              :scene="frontPageItem.scene"
              :env-id="frontPageItem.envId"
              :env-name="frontPageItem.envName"
              :link="frontPageItem.link"
              @add-to-planner="() => handleAddToPlanner({ scene: frontPageItem })"
              @mouseover="() => handleItemHover({ item: frontPageItem })"
              @open-link="({ link }) => handleOpenLink({ link, item: frontPageItem })"
            />
          </div>
        </Slider>
      </div>
    </div>
  </Dock>
</template>

<script>
import { computed, ref, watch, onMounted, onUnmounted, toRefs, inject } from '@vue/composition-api'
import { models } from 'feathers-vuex'
import { get } from 'lodash'
import { makeLink } from '@/use/discover.js'
import { thumbnail } from '@/use/image-transforms.js'
import { useDock } from '@/use/dock.js'

import { Dock } from '@rovit/dock'
import { SceneTile } from '@rovit/scene-tiles'
import { PlusSquareIcon } from 'vue-feather-icons'
import DiscoverCategories from '../DiscoverCategories/DiscoverCategories.vue'
import Swiper from './Swiper.vue'
import DiscoverInfobox from '../DiscoverInfobox/DiscoverInfobox.vue'
import { Slider } from '@rovit/slider'

export default {
  name: 'Discover',
  metaInfo: {
    title: 'Discover'
  },
  components: {
    Dock,
    DiscoverInfobox,
    DiscoverCategories,
    // Swiper,
    Slider
  },
  props: {
    env: {
      validator: val => true,
      default: null
    },
    features: { type: Array, required: true }
  },
  setup(props, context) {
    const $store = inject('$store')

    const { Category, EnvService } = models.api
    const env = computed(() => props.env)
    const dockRef = ref(null)

    // Dock Style
    const dockState = useDock({ page: 'discover' })
    const dockStyle = computed(() => {
      return {
        marginTop: `${dockState.top}px`
      }
    })

    const categories = computed(() => $store.getters['categories/categories'])
    const currentCategory = computed(() => $store.getters['categories/currentCategory'])
    const primaryCategory = computed(() => $store.getters['categories/primaryCategory'])
    const categoryIdStack = computed({
      get: () => $store.getters['categories/categoryIdStack'],
      set: val => $store.dispatch('categories/setCategoryIdStack', val)
    })

    // Front Page Items and Scenes
    const frontPageItems = computed(() => $store.getters['discover/frontPageItems'])
    const categoryItems = computed(() => $store.getters['discover/itemsInCategory'])

    // Dock Drag
    const { setTop } = useDock()
    function handleDockDrag({ movementY }) {
      setTop(movementY)
    }

    // Planner
    async function handleAddToPlanner({ scene }) {
      const { PlannerSavedItem } = models.api
      const data = {
        name: scene.name || scene.title,
        serviceId: scene.service._id,
        sceneId: scene._id,
        sceneName: scene.name || scene.service.name,
        envId: env.value._id,
        envName: env.value.name
      }
      await new PlannerSavedItem(data).save({
        $populateParams: { name: 'withService' }
      })
    }

    // Open Mini Infobox, add a marker and position it visible above the mini infobox.
    function handleOpenLink({ link, item }) {
      $store.dispatch('miniInfobox/openWithLink', link)
      $store.commit('mapMain/setMarkedFeature', item)
      $store.dispatch('mapZoom/pan', {
        center: get(item, 'location.coordinates'),
        forMiniInfobox: true
      })
    }

    // Handle add event.
    function handleAddClick({ envService }) {
      console.log('Open the add menu')
    }

    /**
     * Scenes do not have a direct representation on the map. The map circles
     * represent `map-items` (this was done to load data more efficiently for large envs)
     * This means we need to re-create the _properties data that would normally be
     * added by the `combinedFeatures` getter in the `map-main` vuex module.
     */
    function handleItemHover({ item }) {
      if (!item._properties) {
        item._properties = {
          _id: item._id || item.id,
          mapboxId: parseInt((item._id || item.id).slice(14), 16)
        }
      }
      $store.commit('mapMain/stop')
      setTimeout(() => {
        $store.dispatch('mapMain/handleItemHover', { item })
        $store.commit('mapMain/setMarkedFeature', item)
        $store.dispatch('mapZoom/pan', { center: get(item, 'location.coordinates') })
      }, 100)
    }

    return {
      models,
      dockRef,
      dockStyle,
      makeLink,
      // Scene Tiles
      categoryItems,

      // Front Page Items
      frontPageItems,
      handleOpenLink,
      handleItemHover,

      // Categories
      categories,
      categoryIdStack,
      currentCategory,
      primaryCategory,

      handleAddClick,
      thumbnail,

      // Planner
      handleAddToPlanner,

      // Dock Drag
      handleDockDrag
    }
  }
}
</script>
<style lang="postcss" scoped>
.swiper-slide {
  width: 155px !important;
  height: 132px !important;
}
</style>
