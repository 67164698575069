var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dock',{style:(_vm.dockStyle)},[_c('div',{ref:"dockRef",staticClass:"pb-2 mt-2 text-left",staticStyle:{"min-height":"200px"}},[_c('DiscoverCategories',{staticClass:"max-w-full mb-1 overflow-x-auto overflow-y-hidden",attrs:{"primary-category":_vm.primaryCategory,"category-id-stack":_vm.categoryIdStack,"categories":_vm.categories,"env":_vm.env},on:{"update:categoryIdStack":function($event){_vm.categoryIdStack=$event},"update:category-id-stack":function($event){_vm.categoryIdStack=$event}}}),_c('div',{staticClass:"px-2"},[(_vm.categoryIdStack.length)?_c('Slider',[_c('div',{staticClass:"flex"},_vm._l((_vm.categoryItems),function(scene){return _c('DiscoverInfobox',{key:scene._id,staticClass:"mr-1.5 swiper-slide",attrs:{"scene":scene,"env":_vm.env,"current-category":_vm.currentCategory,"link":_vm.makeLink({ scene: scene, service: scene.service, env: _vm.env })},on:{"add-to-planner":function () { return _vm.handleAddToPlanner({ scene: scene }); },"mouseover":function () { return _vm.handleItemHover({ item: scene }); },"open-link":function (ref) {
	var link = ref.link;

	return _vm.handleOpenLink({ link: link, item: scene });
}}})}),1)]):_c('Slider',[_c('div',{staticClass:"flex"},_vm._l((_vm.frontPageItems),function(frontPageItem){return _c('DiscoverInfobox',{key:frontPageItem._id,staticClass:"mr-1.5 swiper-slide",attrs:{"service":frontPageItem.service,"scene":frontPageItem.scene,"env-id":frontPageItem.envId,"env-name":frontPageItem.envName,"link":frontPageItem.link},on:{"add-to-planner":function () { return _vm.handleAddToPlanner({ scene: frontPageItem }); },"mouseover":function () { return _vm.handleItemHover({ item: frontPageItem }); },"open-link":function (ref) {
	var link = ref.link;

	return _vm.handleOpenLink({ link: link, item: frontPageItem });
}}})}),1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }